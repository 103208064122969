import React, {Component} from 'react';

import './App.css';
import HomePage from "./containers/homePage/HomePage";
import {BrowserRouter, Route, Routes} from "react-router-dom";

class App extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <div className="App">
                <div className="gradient__bg">
                    <BrowserRouter>
                        <Routes>
                            <Route exact path='/' element={<HomePage/>}/>
                        </Routes>
                    </BrowserRouter>

                </div>
            </div>
        );
    }
}


export default App;
