import React from 'react';
import Feature from '../../components/feature/Feature';
import './whyThis.css';


const WhyThis = ({headerText, headerTitle, features, mainTitle}) => (
    <div className="gpt3__whythis" id="WhatIsRockNVerse">
        <div className="gpt3__whythis-heading">
            <h1>{mainTitle}</h1>
            {/*<p>OpenSea.io'ya Git</p>*/}
        </div>
        <div className='container'>
            <div className="row">
                <div className=" col-12 featureContainerMain">
                    {features.map((feature) => {
                        return (
                            <Feature title={feature.title} text={feature.text}/>
                        )
                    })}
                </div>

            </div>
        </div>
    </div>

);

export default WhyThis;
