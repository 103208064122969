import React from 'react';
import './feature.css';
import FeatureLink from "./featureLink/FeatureLink";

const Feature = ({title, text}) => {
    if (typeof text === "object") {
        console.log(text);

    }
    return (
        <div className='featureMain'>
            <div className="featureContainer">
                <div className="featureTitle">
                    <h2>{title}:</h2>
                </div>
                <div className={typeof text === "object" ? 'featureList' : 'featureContent'}>
                    {typeof text === "object" ? text.map((curr) => <FeatureLink link={curr}/>) : text}
                </div>
            </div>
        </div>
    );

};

export default Feature;
