import React from "react";
import './FeatureLink.css';

const FeatureLink = ({link}) => {
    return (
        <div>
            <a className='featureLink' href={`https://${link}`}>{link}</a>
        </div>

    );
}

export default FeatureLink;
