import React, { Component }  from 'react';
import CardList from "../cardList/CardList";
import HeaderHomePage from "../headerHomePage/HeaderHomePage";
import './HomePage.css';
import Legend from "../../components/Legend/Legend";

class HomePage extends Component {
    super() {

    }

    render() {
        return (
            <div className='homePage-background'>
                <HeaderHomePage/>
                <CardList/>
                <div className='homePage-legend'>
                    <Legend/>
                </div>
            </div>
        );
    }
}

export default HomePage;
