let detail;
detail = [
    {
        header: {
            title: 'Ninja Squad',
            description: 'Muzik NFT\'leri icin bir ilk! Yerinizi gecikmeden alin, siz de bizimle ROCK\'layın!',
            img: '/static/media/rocknversetr.f1e7b2a9f9ec8232ca3c.png'

        },
        whyThis: {
            header: {
                mainTitle: 'Proje Hakkında Detaylar',
                feature: {
                    title: 'Rock \'N\' Verse Nedir?',
                    text: 'Rock \'N\' Verse, Tarihin ilk ornegi olarak aramiza katiliyor. Cuzdaniniza baglayabileceginiz, size ozel bir sarkinin olmasi fikri nasil geliyor? Oldukca saglam adimlarla ve istikrarla surdurulen proje yakinda siz degerli NFT severlerle bulusacak.'
                }
            },
            feature: [{
                title: 'Proje Adı',
                text: 'Ninja Squad',
            },
                {
                    title: 'Bulunduğu Ağ',
                    text: 'Ethereum Blockchain'
                },
                {
                    title: 'NFT Sayısı',
                    text: '8,888'
                },
                {
                    title: 'Mint fiyatı ',
                    text: '0,088 ETH'
                },
                {
                    title: 'Presale Mint Tarihi ',
                    text: '19.11.2021'
                },
                {
                    title: 'Public Sale Mint Tarihi ',
                    text: '20.11.2021'
                },
                {
                    title: 'Proje Linkleri',
                    text: ['ninjasquadnft.io/']
                },

            ]
        },
        slogan: 'NFT\'nin muzik hali! - Rock\'n\'Verse',
        visit: {
            visitHeader: 'Ninja Squad',
            visitContent: 'Ninja Squad NFT Koleksiyonu, Crypto Kemal ve ekibi tarafından yaratılan Türkiye\'nin ikinci NFT koleksiyon projesidir. Her biri birbirinden farklı olacak şekilde el ile çizilen bu koleksiyonda farklı “nadirlik” derecesine sahip 8888 adet NFT yer alıyor. Proje discord kanalında kripto para ve nft eko sistemine ilişkin değerli bilgiler ve öngörüler paylaşılıyor. CoinTürk olarak bu projeye ilk günden bu yana ilgi duyuyor ve yakından takip ediyoruz. ',
            visitContentSecondary: '',
            visitImage: 'https://cdn.discordapp.com/attachments/930803771561357364/930804431908405258/meta.png',
            button: 'Websiteye Git'
        },

    },
    {
        header: {
            title: 'Ninja Squad',
            description: 'Muzik NFT\'leri icin bir ilk! Yerinizi gecikmeden alin, siz de bizimle ROCK\'layın!',
            img: '/static/media/rocknversetr.f1e7b2a9f9ec8232ca3c.png'

        },
        whyThis: {
            header: {
                mainTitle: 'Proje Hakkında Detaylar',
                feature: {
                    title: 'Rock \'N\' Verse Nedir?',
                    text: 'Rock \'N\' Verse, Tarihin ilk ornegi olarak aramiza katiliyor. Cuzdaniniza baglayabileceginiz, size ozel bir sarkinin olmasi fikri nasil geliyor? Oldukca saglam adimlarla ve istikrarla surdurulen proje yakinda siz degerli NFT severlerle bulusacak.'
                }
            },
            feature: [{
                title: 'Proje Adı',
                text: 'Ethereum Towers',
            },
                {
                    title: 'Bulunduğu Ağ',
                    text: 'Ethereum Blockchain'
                },
                {
                    title: 'NFT Sayısı',
                    text: '4,388'
                },
                {
                    title: 'Mint fiyatı ',
                    text: '0,08 ETH'
                },
                {
                    title: 'Presale Mint Tarihi ',
                    text: '19.02.2022'
                },
                {
                    title: 'Public Sale Mint Tarihi ',
                    text: 'TBA'
                },
                {
                    title: 'Proje Linkleri',
                    text: ['ethereumtowers.com/']
                },

            ]
        },
        slogan: 'NFT\'nin muzik hali! - Rock\'n\'Verse',
        visit: {
            visitHeader: 'Ethereum Towers',
            visitContent: '1 topluluk, 2 kule, 202 kat ve 4.388 apartman dairesinden oluşan NFT tabanlı bir Metaverse gayrimenkul projesi olan Ethereum Towers güçlü topluluğu ve vizyoner ekibi ile başarılı bir proje lansmanı gerçekleştiriyor. NFT ve Metaverse ilişkisini doğru konumlayan proje mimarisi önümüzdeki yıllarda da adından bolca söz ettirecek.',
            visitContentSecondary: '',
            visitImage: 'https://cdn.discordapp.com/attachments/909742269781917716/928765330220777542/Ethereum_Towers_Final_Render.jpg',
            button: 'Websiteye Git'
        },

    }];
export default detail;
