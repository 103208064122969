import React, {useState} from 'react';
import './visit.css';
import detail from "../../data/detail";

const Visit = ({id}) => {
    const projectId = id;
    let details;
    [details] = useState(detail[projectId]);
    return (
        <div className="visit">
            <div className="visit-content">
                <h3 className='text-center'>{details.visit.visitHeader}</h3>
                <p className=''>
                    {details.visit.visitContent}</p>
                <p>{details.visit.visitContentSecondary}</p>
            </div>
            <div className="visit_img">
                <img src={details.visit.visitImage} alt=""/>
            </div>
        </div>
    )

}


export default Visit;
