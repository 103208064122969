/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useState} from "react";
import './Card.css';
import {Button, Modal} from "react-bootstrap";
import ReactCardFlip from "react-card-flip";
import Detail from "../../containers/detail/Detail";
import ReactTooltip from "react-tooltip";
import Visit from "../visit/Visit";

const Card = (props) => {
    const [show, setShow] = useState(false);
    const [flipped, setFlipped] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleFlip = () => setFlipped(!flipped);
    const {image, image2, title, badge, id, badgeDesc} = props
    return (
        <div className='cardContainer'>
            <div className="up">
                <img className='cardImage' src={image} alt=""/>

            </div>
            <div className="bottom">
                <div className="bottom_image-container">
                    <img className='bottom_image' src={image2} alt=""/>
                </div>
                <div className='bottom_header-container'>
                    <div className='bottom_header-main-container'>
                        <span className='bottom_header-main'>{title}</span>
                    </div>
                </div>
                <div className="bottom_text-container">
                    <img data-tip={badgeDesc} className='cardBadge' src={badge} alt=""/>
                    <ReactTooltip/>
                </div>
                <div className='bottom_button-container' onClick={handleShow}>
                    <a className='neonButton'>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        Detaylara Git
                    </a>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
                    <div>
                        <Visit id={id}/>
                    </div>
                    <div>
                        <Detail id={id}/>
                    </div>
                </ReactCardFlip>
                {/*<div className='cardFront'>*/}
                {/*    <Detail id={0}/>*/}
                {/*</div>*/}
                {/*<div className="cardBack">*/}
                {/*</div>*/}
                <Modal.Footer>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <Button className='closeCardButton' variant="danger" onClick={handleClose}>Kapat</Button>
                        <div className='d-flex'>
                            {
                                flipped ? <p/> : <p className='mb-0 text-white closeText'>Detaylar için &rarr;</p>
                            }
                            <Button className='flipCardButton' onClick={handleFlip}> Kartı Çevir</Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default Card;
