import React from "react";
import './Legend.css';


const Legend = () => {
    return (
        <div className='container d-flex flex-column align-items-center'>
            <div className="">
                <div className="legendItem">
                    <div className="legendImg">
                        <img
                            src='https://cdn.discordapp.com/attachments/940556692951822376/944628464118534204/badgefree.png'
                            alt=""/>
                    </div>
                    <div className="legendText">
                        <p>Yatırım yaptığımız ve ortaklık kurduğumuz proje</p>
                    </div>
                </div>
                <div className="legendItem">
                    <div className="legendImg">
                        <img
                            src='https://cdn.discordapp.com/attachments/940556692951822376/944629184079220818/badge2free.png'
                            alt=""/>
                    </div>
                    <div className="legendText">
                        <p>Sevdiğimiz ve NFT satın aldığımız proje</p>
                    </div>
                </div>
                <div className="legendItem">
                    <div className="legendImg">
                        <img
                            src='https://cdn.discordapp.com/attachments/940556692951822376/944630036340813834/badge3free.png'
                            alt=""/>
                    </div>
                    <div className="legendText">
                        <p>İş birliği yaptığımız proje</p>
                    </div>
                </div>
            </div>

        </div>
    );

};

export default Legend;
