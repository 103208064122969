let data;
data = [
    {
        id: 0,
        category: 'filter1',
        title: 'Ninja Squad',
        badgeDesc: 'Sevdiğimiz ve NFT satın aldığımız proje\n',
        creator: 'Crypto Kemal',
        description: 'MetaHero Identities are a collection of heroes, villains, and mutants native to the MetaHero Univers...',
        image: 'https://cdn.discordapp.com/attachments/940556692951822376/942711938692038687/ninja_cover.png',
        image2: 'https://s2.coinmarketcap.com/static/img/coins/200x200/16807.png',
        badge: 'https://cdn.discordapp.com/attachments/940556692951822376/944629184079220818/badge2free.png',
    },
    {
        id: 1,
        title: 'Ethereum Towers',
        creator: 'Ethereum Towers Deployer',
        badgeDesc: 'İş birliği yaptığımız proje\n',
        image: 'https://cdn.discordapp.com/attachments/940556692951822376/942711938985635840/ET_cover.png',
        image2: 'https://lh3.googleusercontent.com/BuvcYzGxtvGJpO4YEmvYjk2pBLqSwqBm6b-45Bbd3MNkPaMq2JdrQc4jyyUzqVDFOL2WI1PMs-xqWTCPeKtb1VW56yh_i9kVeZsd-Q=s0',
        badge: 'https://cdn.discordapp.com/attachments/940556692951822376/944630036340813834/badge3free.png',
    },
];
export default data;
