import WhyThis from "../whyThis/WhyThis";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import detail from "../../data/detail";
import React, { Component }  from 'react';



const Detail = ({id}) => {
    const projectId = id;
    let details;
    [details] = useState(detail[projectId]);
    const location = useLocation();
    useEffect(() => {

        window.scrollTo(0, 0, [location]);
    });
    return (
        <WhyThis headerTitle={details.whyThis.header.feature.title}
                 headerText={details.whyThis.header.feature.text}
                 mainTitle={details.whyThis.header.mainTitle}
                 features={details.whyThis.feature}/>
    )
}

export default Detail;
