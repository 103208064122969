import ct from "../../assets/8yil.png";
import React from "react";

const HeaderHomePage = () => {
    return (
        <div className='d-flex justify-content-center align-items-center mb-5'>
            <div>
                <a href="https://coin-turk.com/"><img className='header_logo_main' src={ct} alt="hed"/></a>
            </div>
        </div>
    );
}
export default HeaderHomePage;
