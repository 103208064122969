import React, {Component} from "react";
import data from '../../data/data';
import Card from "../../components/card/Card";
import './CardList.css';

class CardList extends Component {

    constructor() {
        super();
        this.state = {
            data: data,
            filterValue: '',
            filter: false,
            filterable: false
        }
    }

    onInputChange = async (filterValue) => {
        if (filterValue !== '') {
            const newDataList = data.filter((curr) => {
                return curr.category === filterValue;
            });
            await this.setState({...this.state, data: newDataList});
        } else {
            await this.setState({...this.state, data: data});
        }
    }

    filterByButtons = async (filterValue) => {
        if (filterValue === this.state.filterValue) {
            await this.setState({filterValue: ''});
            await this.onInputChange('');
        } else {
            await this.setState({...this.state, filterValue});
            await this.onInputChange(filterValue);
        }
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className="row mb-3">
                    <div
                        className="col-12 col-md-4 col-sm-6 d-flex flex-column justify-content-center align-items-center text-center w-100">
                        <h4 className='ctDescription'>CoinTürk olarak NFT ve Metaverse teknolojilerindeki gelişmeleri
                            yakından takip ediyoruz. Sizlere bu konularda haber ve gelişmeleri aktarmanın yanında aktif
                            olarak "CoinTürk Vault" oluşturmaya ve hikayesine güvendiğimiz projeleri sizlerle bu sayfa
                            altında paylaşmaya karar verdik. İlerleyen günlerde koleksiyonumuzdaki NFT'leri de sizlerle
                            paylaşacağız.</h4>
                    </div>
                    <div
                        className="col-12 col-md-4 col-sm-6 d-flex flex-column justify-content-center align-items-center w-100">
                        <h4 className='exploreHeading'>Koleksiyonumuzdaki Projeler</h4>
                    </div>

                </div>
                <div className="row mb-3">
                </div>
                <div className="row cardsContainer">
                    {
                        this.state.data.map((currentData, index) => {

                        return (
                            <div className="col-12 col-lg-4  mb-2">
                                <Card key={index} title={currentData.title}
                                      image={currentData.image} creator={currentData.creator}
                                      badgeDesc={currentData.badgeDesc}
                                      image2={currentData.image2} badge={currentData.badge} id={currentData.id}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default CardList
